<template>
  <div class="anicom-reports">
    <base-loading :waitFlg="waitFlg" />
    <div class="area send">
      <base-button-small-orange
        v-if="tab === 'unsent' && lookOnlyFlg === 0"
        class="button"
        @click="sendAnicomRezept"
        :disabled="checkedMedicalPaymentIds.length === 0 || waitFlg"
        >一括送信</base-button-small-orange
      >
    </div>
    <div class="area search">
      <search-area
        :textBoxLabel="'レセプト検索'"
        :placeholder="'証券番号、飼主名、患者名'"
        :textValue="searchWord"
        :periodFlg="true"
        :periodLabel="'承認番号発行日'"
        :defaultStartDate="defaultStartDate"
        :defaultEndDate="defaultEndDate"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @input="inputSearchWord"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        @search="resetAndGetData"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">検索結果：{{ hitCountText }}</div>
      <div class="order-setting">
        <div class="label">並び替え：承認番号発行日</div>
        <base-select-box
          class="select-box"
          :selectData="orderTypes"
          v-model="orderType"
        />
      </div>
    </div>
    <div class="area list">
      <anicom-report-table
        :data="displayData"
        :allCheckFlg="allCheckFlg"
        :tab="tab"
        v-model="displayData"
        @toggle-all-check-flg="value => (allCheckFlg = value)"
        @scroll-bottom="getNextData"
        @clear="reset"
      />
    </div>
    <announce-popup
      v-if="popup.alertFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="['閉じる']"
      @close="closePopup"
      >{{ popup.message }}</announce-popup
    >
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import SearchArea from '@/components/parts/molecules/SearchArea'
import AnicomReportTable from '@/components/parts/organisms/AnicomReportTable'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { makePaymentDetail } from '@/utils/price_calculation'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'AnicomReports',

  components: {
    BaseLoading,
    SearchArea,
    AnicomReportTable,
    BaseSelectBox,
    BaseButtonSmallOrange,
    AnnouncePopup
  },

  props: {
    tab: { type: String, default: 'unsent' }
  },

  data() {
    return {
      searchedData: [],
      sentData: [],
      searchedMedicalPayments: [],
      unsentData: [],
      searchWord: '',
      startDate: moment()
        .subtract(7, 'd')
        .format('YYYYMMDD'),
      endDate: moment().format('YYYYMMDD'),
      defaultStartDate: moment()
        .subtract(7, 'd')
        .toDate(),
      defaultEndDate: moment().toDate(),
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      allCheckFlg: false,
      popup: {
        alertFlg: false,
        type: '',
        title: '',
        message: ''
      },
      timeoutId: null,
      page: 0,
      unsentHitAllMedicalPaymentsCount: 0,
      sentHitAllMedicalPaymentsCount: 0,
      isFull: false,
      waitFlg: false,
      shouldResetAndGetData: false
    }
  },

  computed: {
    ...mapGetters({
      getAnicomReports: 'anicomReports/getDataByMedicalPaymentIdIncludeDel',
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getAnicomCIdCheck: 'anicomCIdChecks/getDataById',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      getMedicalPaymentByPatientId: 'medicalPayments/getDataByPatientId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    hitCountText() {
      return this.tab === 'sent'
        ? `送信済 ${this.sentHitAllMedicalPaymentsCount}件`
        : `未送信 ${this.unsentHitAllMedicalPaymentsCount}件`
    },
    displayData: {
      get() {
        return this.tab === 'sent' ? this.sentData : this.unsentData
      },
      set(obj) {
        this.tab === 'sent'
          ? (this.sentData[obj.index].isChecked = obj.value)
          : (this.unsentData[obj.index].isChecked = obj.value)
      }
    },
    checkedMedicalPaymentIds() {
      return this.tab === 'unsent'
        ? this.unsentData.flatMap(ud => {
            return ud.isChecked ? ud.id : []
          })
        : this.sentData.flatMap(sd => {
            return sd.isChecked ? sd.id : []
          })
    }
  },

  watch: {
    tab: function() {
      //タブを切り替えた時チェックを全てはずす
      this.unsentData = this.unsentData.map(ud => {
        return { ...ud, isChecked: false }
      })
      this.sentData = this.sentData.map(sd => {
        return { ...sd, isChecked: false }
      })
      this.allCheckFlg = false
    },
    allCheckFlg: function() {
      if (this.allCheckFlg) {
        if (this.tab === 'unsent') {
          this.unsentData = this.unsentData.map(ud => {
            return ud.isSend === '可' || ud.isSend === 'キャンセル可'
              ? { ...ud, isChecked: true }
              : { ...ud, isChecked: false }
          })
        } else {
          this.sentData = this.sentData.map(sd => {
            return sd.isSend === '可' || sd.isSend === 'キャンセル可'
              ? { ...sd, isChecked: true }
              : { ...sd, isChecked: false }
          })
        }
      } else {
        if (this.tab === 'unsent') {
          this.unsentData = this.unsentData.map(ud => {
            return { ...ud, isChecked: false }
          })
        } else {
          this.sentData = this.sentData.map(sd => {
            return { ...sd, isChecked: false }
          })
        }
      }
    },
    medicalPayments: {
      deep: true,
      handler() {
        this.makeFilteredMedicalPayments()
      }
    },
    orderType: async function() {
      await this.resetAndGetData()
    }
  },

  async created() {
    await this.getData()
    // レセプト締切の更新時間の為、午前3時0分5秒に再描画する。
    this.timeoutId = window.setTimeout(async () => {
      await this.resetAndGetData()
    }, moment().diff(moment('0305', 'HHss')))
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    async resetAndGetData() {
      this.sentData = []
      this.unsentData = []
      this.searchedMedicalPayments = []
      this.page = 0
      this.unsentHitAllMedicalPaymentsCount = 0
      this.sentHitAllMedicalPaymentsCount = 0
      this.isFull = false
      this.allCheckFlg = false
      await this.getData()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.getData()
    },
    async getData() {
      this.waitFlg = true
      const res = await this.$store.dispatch('anicomReports/search', {
        searchWord: this.searchWord,
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.page,
        order: this.orderType === 0 ? 'DESC' : 'ASC'
      })
      if (res.result === 'success') {
        this.searchedMedicalPayments = this.searchedMedicalPayments.concat(
          res.hitPartMedicalPayments
        )
        this.unsentHitAllMedicalPaymentsCount =
          res.unsentHitAllMedicalPaymentsCount
        this.sentHitAllMedicalPaymentsCount = res.sentHitAllMedicalPaymentsCount
        const { sentData, unsentData } = this.makeData(
          res.hitPartMedicalPayments
        )
        this.sentData = this.sentData.concat(sentData)
        this.unsentData = this.unsentData.concat(unsentData)
        this.page++
        if (
          res.hitPartMedicalPayments.length === 0 ||
          this.searchedMedicalPayments.length < 100
        ) {
          this.isFull = true
        }
        if (this.page >= 10 && this.searchedMedicalPayments.length >= 1000) {
          this.isFull = true
          this.popup = {
            alertFlg: true,
            type: 'alert',
            title: '警告',
            message: 'レセプトの表示件数が上限の1000件に達しました。'
          }
        }
        this.waitFlg = false
        this.judgeGetNextData()
      } else {
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message: '通信エラーが発生しました。'
        }
        this.waitFlg = false
      }
    },
    judgeGetNextData() {
      if (this.isFull) return
      if (
        (this.unsentData.length < 13 &&
          this.unsentData.length < this.unsentHitAllMedicalPaymentsCount) ||
        (this.sentData.length < 13 &&
          this.sentData.length < this.sentHitAllMedicalPaymentsCount)
      ) {
        // スクロールバーが出ないと下端に移動した時に次の100件のデータを取得する動作が動かないため、スクロールバーが出るまで次のデータを取得する。
        // データが12個以上あるとスクロールバーが表示されており、12件目のデータが画面内に一部表示されているため、12件まではこのメソッド内で取得する
        this.getData()
      }
    },
    makeData(medicalPayments) {
      const addData = medicalPayments.map(medicalPayment => {
        const anicomReport = this.findAnicomReport(medicalPayment)
        const {
          patient,
          owner,
          paymentStatus,
          sentStatus,
          isSend
        } = this.getCommonData(medicalPayment, anicomReport)
        const commonProperties = {
          id: medicalPayment.id,
          isSend,
          sentStatus,
          isChecked: false
        }
        if (anicomReport?.isValid) {
          return {
            ...commonProperties,
            ownerLastName: anicomReport.cSname,
            patientName: anicomReport.aniName,
            rezeptCdDate: this.rewriteDate(anicomReport.rezeptCdDate),
            anicomCId: anicomReport.cId,
            medicalExpenses: '￥' + anicomReport.dicAmount.toLocaleString(),
            paymentStatus: medicalPayment.delFlg
              ? '診療明細削除'
              : paymentStatus
          }
        } else if (medicalPayment.delFlg === 0) {
          const anicomCIdCheck = this.getAnicomCIdCheck(
            medicalPayment.anicomCIdCheckId
          )
          return {
            ...commonProperties,
            ownerLastName: owner.lastName,
            patientName: patient.name,
            rezeptCdDate: this.rewriteDate(anicomCIdCheck.date),
            anicomCId: anicomCIdCheck?.patientCId || '',
            medicalExpenses:
              '￥' + medicalPayment.taxIncludedPrice.toLocaleString(),
            paymentStatus
          }
        }
      })
      const sentData = addData.filter(v => v.sentStatus === 'sent')
      const unsentData = addData.filter(v => v.sentStatus === 'unsent')
      return { sentData, unsentData }
    },
    findAnicomReport(medicalPayment) {
      const medicalPaymentHistory = this.getMedicalPaymentHistory(
        medicalPayment.originalId
      )
      let anicomReport
      medicalPaymentHistory.some(mp => {
        const anicomReports = this.getAnicomReports(mp.id)
        if (Array.isArray(anicomReports) && anicomReports.length > 0) {
          anicomReport = anicomReports[anicomReports.length - 1]
          anicomReport.isValid =
            anicomReport.resultFlg === 1 &&
            anicomReport.httpStatus === 200 &&
            anicomReport.cancelFlg === 0
          return anicomReport.isValid
        } else {
          anicomReport = null
          return false
        }
      })
      return anicomReport
    },
    getCommonData(medicalPayment, anicomReport) {
      const patient = this.getPatient(medicalPayment.patientId)
      const owner = this.getOwner(patient.ownerId)
      const paymentStatus = makePaymentDetail(
        medicalPayment,
        this.getMedicalPaymentHistory,
        this.getMedicalRecordByOriginalId,
        this.getPaymentsByMedicalPaymentId
      ).type
      let sentStatus
      if (!anicomReport) {
        // レセプト送信していない時は、未送信
        sentStatus = 'unsent'
      } else {
        if (
          // レセプト送信が失敗またはレセプトがキャンセルされている時は、未送信
          (anicomReport.resultFlg !== 1 && anicomReport.httpStatus !== 200) ||
          (anicomReport.cancelFlg === 1 && anicomReport.delFlg === 1)
        ) {
          sentStatus = 'unsent'
        } else {
          sentStatus = 'sent'
        }
      }
      const isSend = this.makeIsSend(
        medicalPayment,
        anicomReport,
        paymentStatus,
        sentStatus
      )
      return {
        patient,
        owner,
        paymentStatus,
        sentStatus,
        isSend
      }
    },
    makeIsSend(medicalPayment, anicomReport, paymentStatus, sentStatus) {
      if (
        anicomReport &&
        anicomReport.cancelFlg === 0 &&
        medicalPayment.id !== anicomReport.medicalPaymentId
      ) {
        return '不可(診療明細変更済み)'
      } else if (anicomReport && sentStatus === 'sent') {
        const sentDate = moment(anicomReport.sendDate).format('YYYY-MM-DD')
        const limitDate = moment(anicomReport.sendDate).isBetween(
          sentDate + ' 00:00',
          sentDate + ' 03:00',
          'hour'
        )
          ? moment(anicomReport.sendDate).format('YYYYMMDD') + '03'
          : moment(anicomReport.sendDate)
              .add(1, 'd')
              .format('YYYYMMDD') + '03'
        return moment().isBefore(moment(limitDate, 'YYYYMMDDHH'))
          ? 'キャンセル可'
          : '不可(締切)'
      } else if (paymentStatus === '再会計' || paymentStatus === '未会計') {
        return '不可(会計待ち)'
      } else {
        return '可'
      }
    },
    rewriteDate(YYYYMMDD) {
      return moment(YYYYMMDD, 'YYYYMMDD').format('YYYY年MM月DD日')
    },
    inputSearchWord(text) {
      this.searchWord = text
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    async sendAnicomRezept() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'anicomReports/create',
        this.checkedMedicalPaymentIds
      )
      if (res.status === 200) {
        const { sentData, unsentData } = this.makeData(
          this.searchedMedicalPayments
        )
        this.sentData = sentData
        this.unsentData = unsentData
        const errorAnicomReports = res.anicomReports.filter(
          v => v.resultFlg === 0 || v.httpStatus !== 200
        )
        if (errorAnicomReports.length === 0) {
          this.popup = {
            alertFlg: true,
            type: 'success',
            title: '成功',
            message: 'レセプトを送信しました。'
          }
        } else {
          const message = errorAnicomReports.reduce((msg, report) => {
            msg += `・証券番号: ${report.cId}, 飼主姓: ${report.cSname}, 患者名: ${report.aniName}\n`
            return msg
          }, '以下のレセプト送信に失敗しました。\n')
          this.popup = {
            alertFlg: true,
            type: 'failure',
            title: '失敗',
            message
          }
        }
      } else {
        let message = '通信エラーが発生しました。'
        if (res.message === 'no medicalPayment') {
          message =
            '変更または削除された診療明細が含まれていた為、レセプトを送信できませんでした。\n最新データを再取得します。'
          this.shouldResetAndGetData = true
        } else if (res.message === 'no payment') {
          message =
            '削除された会計が含まれていた為、レセプトを送信できませんでした。\n最新データを再取得します。'
          this.shouldResetAndGetData = true
        }
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message
        }
      }
      this.allCheckFlg = false
      this.waitFlg = false
    },
    reset(medicalPaymentId) {
      this.searchedMedicalPayments = this.searchedMedicalPayments.filter(
        smp => smp.id !== medicalPaymentId
      )
      const { sentData, unsentData } = this.makeData(
        this.searchedMedicalPayments
      )
      this.sentData = sentData
      this.unsentData = unsentData
    },
    async closePopup() {
      this.popup.alertFlg = false
      if (this.shouldResetAndGetData) {
        await this.resetAndGetData()
        this.shouldResetAndGetData = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.anicom-reports {
  > .send {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  > .search {
    margin-top: 20px;
  }
  > .search-order {
    margin-top: 30px;
    display: flex;
    font-size: 13px;
    height: 33px;
    > .order-result {
      line-height: 33px;
      margin-left: 10px;
    }
    > .order-setting {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      > .select-box {
        margin-left: 10px;
      }
    }
    > .button {
      ::v-deep .text {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  > .list {
    margin-top: 15px;
  }
}
</style>
